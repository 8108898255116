<template>
    <div class="dialog_box">
        <el-dialog title="离职操作" :visible.sync="shows" :close-on-click-modal="false"  :close-on-press-escape="false" @closed="onclosed" >
            <div class="form_box">
                <div class="form_flex">
                    <div class="form_item">
                        <div class="form_title"><span>*</span>姓名:</div>
                        <div class="form_input1 form_input1_bg">
                            <input type="text" placeholder="请输入姓名" v-model="form.name" disabled>
                        </div>
                    </div>
                    <div class="form_item">
                        <div class="form_title"><span>*</span>离职类型:</div>
                        <div class="form_input2">
                            <el-select v-model="form.type" placeholder="请选择">
                                <el-option
                                v-for="item in lzlx_list"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="form_item">
                        <div class="form_title"><span>*</span>离职原因:</div>
                        <div class="form_input2">
                            <el-select v-model="form.reason" placeholder="请选择">
                                <el-option
                                v-for="item in lzyy_list"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="form_item">
                        <div class="form_title"><span>*</span>离职时间:</div>
                        <div class="form_input3">
                            <el-date-picker v-model="form.time" type="date" placeholder="请选择离职时间"  format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="form_item">
                        <div class="form_title"><span>*</span>文号:</div>
                        <div class="form_input1">
                            <input type="text" placeholder="请输入文号" v-model="form.order_no">
                        </div>
                    </div>
                    <div class="form_item2">
                        <div class="form_title"><span>*</span>离职文件:</div>
                        <div class="form_input4">
                             <div class="btns" @click="handle_up">上传附件</div>
														 		<upload_tip />
																
                             <div class="file_flex" v-if="form.pdf.path">
                                <div class="files_text">{{form.pdf.name}}</div>
                                <div class="files_clear" @click="handle_up_clear">
                                    <img src="../../assets/img/employee/clear.png" alt="">
                                </div>
                             </div>
                        </div>
                    </div>
                    <div class="form_item">
                        <div class="form_title"><span>*</span>文件日期:</div>
                        <div class="form_input3">
                            <el-date-picker v-model="form.pdf_time" type="date" placeholder="请选择出生日期"  format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                    </div>
                </div>
                <div class="form_btns">
                    <div class="btns1" @click="handle_ends">保存</div>
                    <div class="btns2" @click="handle_close">取消</div>
                </div>
                
            </div>
        </el-dialog>
        <upload_img ref="upload_img"></upload_img>
    </div>
</template>
<script>
import upload_img from '../upload/upload.vue'
export default {
    name: "Customer_Information_form",
    components:{
        upload_img,
    },
    props: {

    },
    data() {
        return {
            shows: false,
            form:{
                id:'',
                name:'',//姓名
                type:'',//离职类型
                time:'',//离职时间
                gw_title:'',//岗位
                order_no:'',//单号
                job_title:'',//单位
                gw_title:'',//岗位
                pdf:{
                    name:'',
                    path:'',
                },//离职文件
                pdf_time:'',//文件时间
                reason:'',//离职原因
            },
            lzlx_list:[
                {
                    title:'辞职',
                    value:'辞职',
                },
                {
                    title:'调动',
                    value:'调动',
                },
                {
                    title:'解除',
                    value:'解除',
                },
                {
                    title:'终止',
                    value:'终止',
                },
                {
                    title:'退休',
                    value:'退休',
                },
                {
                    title:'死亡',
                    value:'死亡',
                },
                {
                    title:'其他',
                    value:'其他',
                },
            ],
            lzyy_list:[
                {
                    title:'个人原因',
                    value:'个人原因',
                },
                {
                    title:'其他',
                    value:'其他',
                },
            ],

        };
    },
    methods: {
        onclosed(){
            this.form={
                id:'',
                name:'',//姓名
                type:'',//离职类型
                time:'',//离职时间
                gw_title:'',//岗位
                order_no:'',//单号
                job_title:'',//单位
                gw_title:'',//岗位
                pdf:{
                    name:'',
                    path:'',
                },//离职文件
                pdf_time:'',//文件时间
                reason:'',//离职原因
            }
        },

        //打开
        handle_open(row) {
            this.form.id=row.id
            this.form.name=row.name
            this.form.job_title = row.depart_title
            this.form.gw_title =row.level6
            this.shows = true
        },
        //关闭    
        handle_close() {
            this.shows = false
        },
        handle_up(){
            this.$refs.upload_img.uploadFile()
        },
        upload_url(url){
            console.log(url)
            this.form.pdf=url
        },
        handle_up_clear(){
            this.form.pdf={}
        },
        //确认
        handle_ends() {
            this.loading = true
            this.form.pdf=JSON.stringify(this.form.pdf)
            this.$api("editUserDimission", {
                ...this.form
            }, "post").then((res) => {
                this.loading = false
                if (res.code == 200) {
                    this.shows = false

                    this.$emit("handle_get_list");

                } else {
                    alertErr(res.msg)
                }
            });
        },
    },
    computed: {
    },

    watch: {
        
    },


    created() {


    },


};
</script>

<style scoped lang="less">
.dialog_box {
    /deep/.el-dialog {
        width: 760px;
    }

    /deep/.el-dialog__header {
        background: #F2F5FA !important;
    }

    .form_box {
       
        .form_flex{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            border-bottom: 1px solid #EEEEEE ;
            padding-bottom: 23px;
            .form_item{
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 17px;
                .form_title{
                    width: 130px;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    text-align: right;
                    span{
                        color: #FC5F03;
                        margin-right: 5px;
                    }

                }
                .form_input1{
                    width: 100%;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 2px 2px 2px 2px;
                    border: 1px solid #D4D4D4;
                    margin-left: 11px;
                    input{
                        width: 100%;
                        height: 40px;
                        padding: 0 10px;                      
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #333333;

                    }
                }
                .form_input1_bg{
                    background-color:#D4D4D4 ;
                }
                .form_input2{
                    width: 100%;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 2px 2px 2px 2px;
                    margin-left: 11px;
                    display: flex;
                    align-items: center;
                    /deep/.el-select{
                        width: 100%;
                    }
                }
                .form_input3{
                    width: 100%;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 2px 2px 2px 2px;
                    margin-left: 11px;
                    display: flex;
                    align-items: center;
                    /deep/.el-date-editor {
                        width: 100%;
                    }
                }
                .form_input4{
                    width: 100%;
                    margin-left: 11px;

                    .btns{
                        width: 91px;
                        height: 31px;
                        background: linear-gradient( 90deg, #157DE9 0%, #3697FD 100%);
                        border-radius: 4px 4px 4px 4px;
                        text-align: center;
                        line-height: 31px;
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #FFFFFF; 
                        cursor: pointer;
                    }
                    .file_flex{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 20px;
                        .files_text{
                            font-family: Microsoft YaHei, Microsoft YaHei;
                            font-weight: 400;
                            font-size: 14px;
                            color: #333333;
                        }
                    }
                 
                  
                  
                }
            }
            .form_item2{
                width: 100%;
                display: flex;
                margin-bottom: 17px;
                .form_title{
                    width: 130px;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    text-align: right;
                    span{
                        color: #FC5F03;
                        margin-right: 5px;
                    }

                }
                 
                .form_input4{
                    width: 100%;
                    margin-left: 11px;

                    .btns{
                        width: 91px;
                        height: 31px;
                        background: linear-gradient( 90deg, #157DE9 0%, #3697FD 100%);
                        border-radius: 4px 4px 4px 4px;
                        text-align: center;
                        line-height: 31px;
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #FFFFFF;
                        cursor: pointer;
                    }
                    .file_flex{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 20px;
                        .files_text{
                            font-family: Microsoft YaHei, Microsoft YaHei;
                            font-weight: 400;
                            font-size: 14px;
                            color: #333333;
                        }
                        .files_clear{
                            cursor: pointer;
                            img{
                                width: 15px;
                                height: 15px;
                            }

                        }
                    }
                 
                  
                  
                }
            }
        }
        .form_btns{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 18px;
            .btns1{
                width: 91px;
                height: 38px;
                background: #2373C8;
                border-radius: 4px 4px 4px 4px;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
                text-align: center;
                line-height: 38px;
                cursor: pointer;
            }
            .btns2{
                width: 91px;
                height: 38px;
                background: #FFFFFF;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #B8B8B8; 
                text-align: center;
                line-height: 38px;
                cursor: pointer;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #6A6A6A;
                margin-left: 22px;
            }
        }
    }
}
</style>