<template>

    <div class="dialog_box">
        <el-dialog title="导入员工信息" :visible.sync="shows" :close-on-click-modal="false"  :close-on-press-escape="false">
            <div class="form_box">
                
                <div class="type_box">
                    <div class="lefts">
                        <div class="icons">1</div>
                        <div class="bordes"></div>
                    </div>
                    <div class="rights">
                        <div class="rights_top">
                            <div class="text_box">
                                <div class="text1">下载人员信息表格模板，批量填写人员信息</div>
                                <div class="text2"><span>*</span>建议使用WPS进行表格编辑</div>
                            </div>
                            <div class="btn_box">
                                <div class="btns">选择文件</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="type_box">
                    <div class="lefts">
                        <div class="icons">2</div>
                        <div class="bordes2"></div>
                    </div>
                    <div class="rights">
                        <div class="rights_top">
                            <div class="text_box">
                                <div class="text1">选择填好的人员信息表格</div>
                                <div class="text2"><span>*</span>选择填好的人员信息表格</div>
                            </div>
                            <div class="btn_box">
                                <div class="btns">选择文件</div>
                            </div>
                        </div>
                        <div class="rights_bom">
                            <div class="up_files">
                                <span class="span1">人员表格.xls</span>
                                <!-- <span class="span2">X</span> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="type_box">
                    <div class="lefts2">
                        <div class="icons">3</div>
                    </div>
                    <div class="rights">
                        <div class="rights_top">
                            <div class="text_box">
                                <div class="text1">上传文件</div>
                            </div>
                            <div class="btn_box">
                                <div class="btns">上传文件</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>

    </div>
</template>
<script>

export default {
    name: "Customer_Information_form",
    props: {

    },
    data() {
        return {
            shows: false,
            titles: '',
            ids: [],

        };
    },
    methods: {

        //打开
        handle_open(ids, i) {
            this.ids = ids

            this.shows = true
        },
        //关闭    
        handle_close() {
            this.shows = false

        },
        //确认
        handle_ends() {
            this.loading = true
            let ids = this.ids.join(',')
            this.$api("setEmployeeStatus", {
                ids: ids,
                status: 'open'

            }, "post").then((res) => {
                this.loading = false
                if (res.code == 200) {
                    this.shows = false
                    alertSucc(this.$t('msg_open_success'))

                    this.$emit("handle_get_list");

                } else {
                    alertErr(res.msg)
                }
            });
        },
    },
    computed: {
    },

    watch: {
        
    },


    created() {


    },


};
</script>

<style scoped lang="less">
.dialog_box {
    /deep/.el-dialog {
        width: 600px;
    }

    /deep/.el-dialog__header {
        background: #F2F5FA !important;
    }

    .form_box {
        .type_box {
            display: flex;

            // align-items: center;
            .lefts {
                width: 21px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                .icons {
                    width: 21px;
                    height: 21px;
                    background: #2373C8;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 21px;
                    border-radius: 50%;

                }

                .bordes {
                    margin: auto;
                    height: 87px;
                    border-right: 3px dotted #2373C8;
                }
                .bordes2{
                    margin: auto;
                    height: 137px;
                    border-right: 3px dotted #2373C8;
                }
            }
            .lefts2{
                width: 21px;
                display: flex;
                .icons {
                    width: 21px;
                    height: 21px;
                    background: #2373C8;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 21px;
                    border-radius: 50%;

                }
            }

            .rights {
                padding-left: 14px;

                .rights_top {
                    display: flex;

                    .text_box {

                        .text1 {
                            font-weight: 400;
                            font-size: 14px;
                            color: #333333;
                        }

                        .text2 {
                            font-family: Microsoft YaHei, Microsoft YaHei;
                            font-weight: 400;
                            font-size: 13px;
                            color: #888888;
                            margin-top: 9px;

                            span {
                                margin-right: 2px;
                                color: #FF0000;
                            }
                        }
                    }

                    .btn_box {
                        margin-left: 54px;

                        .btns {
                            width: 91px;
                            height: 31px;
                            background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
                            border-radius: 4px 4px 4px 4px;
                            cursor: pointer;
                            line-height: 31px;
                            font-family: Microsoft YaHei, Microsoft YaHei;
                            font-weight: 400;
                            font-size: 14px;
                            color: #FFFFFF;
                            text-align: center;

                            &:hover {

                                background: linear-gradient(90deg, #036ad8 0%, #157DE9 100%);
                            }
                        }
                    }
                }
                .rights_bom{
                    display: flex;
                    .up_files{
                        margin-top: 24px;
                        padding: 8px 10px;
                        background: #F7F8FA;
                        border-radius: 4px 4px 4px 4px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .span1{
                            font-family: Microsoft YaHei, Microsoft YaHei;
                            font-weight: 400;
                            font-size: 14px;
                            color: #CD962A;
                        }
                    }
                }

            }
        }
    }

}
</style>